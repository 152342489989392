/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../../components/Layout'
import ThemeWrapper from '../../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Image, Text, Subtitle, Button, ContactForm } from '@swp/components'
import SiteHeader from '../../components/pl/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Položka"}>
        <SiteHeader />

        <Column className="js-anim  --anim3 --anim-s4 pb--60 pt--80" anim={"3"} name={"0il8bnfu5zat"} animS={"4"}>
          
          <ColumnWrap className="column__flex --center el--1 mt--60" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box w--400" style={{"maxWidth":1110}} content={"Monstera — 27cm"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--40" name={"8jss9bj1yde"} layout={"l8"}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s4 --center el--2 pb--40 pt--40 flex--top" anim={"2"} animS={"4"} columns={"2"}>
            
            <ColumnWrapper >
              
              <Image alt={"Kliknij, aby edytować obraz"} src={"https://cdn.swbpg.com/t/i/template/101/img-3_s=350x_.jpg"} svg={false} ratio={"3:4"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/101/img-3_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/101/img-3_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/101/img-3_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/101/img-3_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/101/img-3_s=2000x_.jpg 2000w"} content={null}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="--left" style={{"maxWidth":500}}>
              
              <Image className="pl--02 pr--02" alt={""} src={"https://cdn.swbpg.com/o/11212/1fd2dfd170f14244acf45f8fe698f706.svg"} svg={false} sizes={"100vw"} style={{"maxWidth":32}} srcSet={""} content={null}>
              </Image>

              <Text className="text-box" content={"Kliknij i zacznij pisać, to pole jest przeznaczone na tekst. W razie potrzeby można zmienić kolor bloku tekstu, zmienić wielkość liter, zmienić odstępy między wierszami, wyjustować tekst oraz ustawić maksymalną szerokość tekstu w pikselach."}>
              </Text>

              <Subtitle className="subtitle-box" content={"33,— zł"}>
              </Subtitle>

              <Button className="btn-box btn-box--hvr2 btn-box--shape5 btn-box--filling2 fs--20" href={"#3m21xzdv5bk"} content={"Zamów teraz"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
          </ColumnWrap>

        </Column>


        <Column className="pb--80 pt--80" name={"44kd964prvw"} style={{"backgroundColor":"var(--color-supplementary)"}}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s4 --left el--1 pb--40 pt--40" anim={"2"} animS={"4"} style={{"maxWidth":1400}} columns={"1"}>
            
            <ColumnWrapper className="--left" style={{"maxWidth":869}}>
              
              <Title className="title-box" content={"<span style='color: var(--color-dominant)'>Monstera jest znana z dużych liści</span>"}>
              </Title>

              <Text className="text-box" content={"<span style='color: var(--color-dominant)'>Kliknij i zacznij pisać, to pole jest przeznaczone na tekst. W razie potrzeby można zmienić kolor bloku tekstu, zmienić wielkość liter, zmienić odstępy między wierszami, wyjustować tekst oraz ustawić maksymalną szerokość tekstu w pikselach.</span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s4 --left el--3 pb--60 pt--40" anim={"2"} animS={"4"} style={{"maxWidth":""}} columns={"3"}>
            
            <ColumnWrapper className="--left" style={{"maxWidth":""}}>
              
              <Image alt={"Kliknij, aby edytować obraz"} src={"https://cdn.swbpg.com/t/i/template/101/img-2_s=350x_.jpg"} svg={false} ratio={"3:4"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/101/img-2_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/101/img-2_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/101/img-2_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/101/img-2_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/101/img-2_s=2000x_.jpg 2000w"} content={null}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="--left" style={{"maxWidth":""}}>
              
              <Image alt={"Kliknij, aby edytować obraz"} src={"https://cdn.swbpg.com/t/i/template/101/img-3_s=350x_.jpg"} svg={false} ratio={"3:4"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/101/img-3_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/101/img-3_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/101/img-3_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/101/img-3_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/101/img-3_s=2000x_.jpg 2000w"} content={null}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="--left" style={{"maxWidth":""}}>
              
              <Image alt={"Kliknij, aby edytować obraz"} src={"https://cdn.swbpg.com/t/i/template/101/img-3_s=350x_.jpg"} svg={false} ratio={"3:4"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/101/img-3_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/101/img-3_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/101/img-3_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/101/img-3_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/101/img-3_s=2000x_.jpg 2000w"} content={null}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--80 pt--60" name={"3m21xzdv5bk"}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s4 --center el--1 pb--40 pt--40" anim={"2"} animS={"4"} style={{"maxWidth":1400}} columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"Zamówienie"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper className="mt--40">
              
              <ContactForm className="--shape2 --style1 fs--18" style={{"maxWidth":1682}} action={"/contact"} fields={[{"name":"Pełne imię","type":"text","required":true,"placeholder":"Wpisz pełne imię"},{"name":"Kontakt e-mail","type":"email","required":true,"placeholder":"Wpisz swój e-mail"},{"name":"Numer telefonu","type":"text","placeholder":"Telefon +480"},{"name":"Wiadomość","type":"textarea","required":false,"placeholder":"Wpisz tekst wiadomości"},{"name":"Zamów teraz","type":"submit"}]}>
              </ContactForm>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--80 pt--80" name={"stopka"} style={{"backgroundColor":"var(--color-supplementary)"}}>
          
          <ColumnWrap className="column__flex --center el--3 flex--center" style={{"maxWidth":1400}} columns={"3"}>
            
            <ColumnWrapper className="--left">
              
              <Text className="text-box" content={"<span style='color: var(--color-dominant)'><a href=\"https://saywebpage.com\">Tworzenie stron</a><br>Pańska 18, Warszawa</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--center">
              
              <Image alt={""} src={"https://cdn.swbpg.com/o/11212/cd8db1a198b540c896a32b45d514eef3.svg"} svg={false} sizes={"100vw"} style={{"maxWidth":64}} srcSet={""} content={null}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="--left">
              
              <Text className="text-box text-box--right" content={"<span style='color: var(--color-dominant)'>+48 797 811 2X0<br>info@twoje-stronyy.com</span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}